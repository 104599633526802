<template>
  <div id="ship">
    <tmpl_header />

    <div class="container">
      <div class="breadcrumb">
        <ul>
          <li class="r">
            <router-link to="/center/order">我的订单</router-link>
          </li>
          <li>
            <a href="javascript:;">物流详情</a>
          </li>
        </ul>
      </div>

      <div class="card ship">
        <ol>
          <li class="act">包裹1</li>
          <li>包裹2</li>
        </ol>
        <ul>
          <li class="target act">
            <h3>顺丰快递 运单号码：<i>768348747838488</i></h3>
            <p class="addr">[收货地址] 上海市静安区200304</p>
          </li>
          <li class="sign act">
            <h3>已签收</h3>
            <p>
              <small>2022-03-23 20:35</small
              >快件已在上海浦东新区签收，签收人：本人。感谢使用顺丰，期待再次为您服务
            </p>
          </li>
          <li class="distribute">
            <h3>派送中</h3>
            <p>
              <small>2022-03-23 20:35</small> 【上海市】顺丰快递派件员 | 派大星
              <b>18778325105</b> 正在为您派件
            </p>
          </li>
          <li class="transit">
            <h3>运输中</h3>
            <p>
              <small>2022-03-23 20:35</small
              >【嘉兴市】快件已经从嘉兴中转站发出，准备发往上海市
            </p>
            <p>
              <small>2022-03-23 20:35</small
              >【嘉兴市】快件已经从嘉兴中转站发出，准备发往上海市
            </p>
          </li>
        </ul>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import api from "../../api/index";
// import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/info") },
      });

    api.all([this.loadData()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadData: function () {},
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./ship.less");
</style>
